#footer {
  font-size: 1.7rem;
  position: relative;
  bottom: 0;
  color: var(--text-field-color);
}

@media (max-width: 479px) {
  #footer {
    bottom: 17px;
  }
}

#footer h5  {
  /* padding-bottom: 6px; */
  margin-bottom: 20px;
  color: var(--text-field-color);
  font-size: large;
  font-weight:bolder;
}
.col{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#footer span {
  color: var(--text-field-color);
  font-weight: 700;
  font-size: medium;
}

.user-profile{
  font-size: medium;
  line-height: 14px;
  font-weight: 700;
  text-decoration: none;
  color: var(--text-field-color);
}

.footer1{
  background: #015CA1;
  height: 70vh !important;
}
.footer2 p{
  color: #126BD4;
  font-size: medium;
}
.image{
  border: 1px solid black;
  background: #015CA1;
  border-radius: 8px;
}

.footer2{
  background-color: white;
  height: 20vh !important;
}
